<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Kasbon # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="lastKasbonNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  :items="employees"
                  item-text="name"
                  return-object
                  background-color="white"
                  v-model="employee"
                  @change="onChangeEmployee"
                  :rules="[v => !!v || 'Name is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    :allowed-dates="getAllowedDates()"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Payment</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.paymentMethod"
                  :items="listPaymentMethod"
                  :rules="[v => !!v || 'Payment is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.paymentMethod === 'Transfer'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <!-- <v-select
                  flat
                  dense
                  outlined
                  background-color="white"
                  :items="listAccountNumber"
                  item-value="id"
                  item-text="accountNumber"
                  v-model="form.accountNumberId"
                  :rules="[v => !!v || 'Account Number is required']"
                >
                </v-select> -->
                <v-text-field
                  flat
                  dense
                  outlined
                  background-color="white"
                  v-model="form.bankAccountNumber"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Division</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  background-color="white"
                  v-model="form.division"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.currency"
                  :items="dropDownCurrency"
                  :rules="[v => !!v || 'Currency is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Old Assign</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.oldAssign"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>WO#<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  :items="listWo"
                  item-text="id"
                  background-color="white"
                  v-model="wo"
                  @change="onChangeWo"
                  :filter="filterObjectWo"
                  return-object
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ data.item.id }} ( {{ woDate(data.item.tasks) }})</v-list-item-title
                      >
                      <v-list-item-subtitle>{{ woSite(data.item.tasks) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        :items="listCategory"
                        item-text="category"
                        return-object
                        v-model="category"
                        @change="onChangeCategory"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.category"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Requirement <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                        <template v-slot:item.usedAmount="{ item }">
                          {{ formatPrice(item.usedAmount) }}
                        </template>
                        <template v-slot:item.remainAmount="{ item }">
                          {{ formatPrice(item.remainAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="12" class="text-right pb-0">
                      <p class="headline font-italic">WO <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersWo"
                        :items="form.wos"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.action="{ index }">
                          <v-btn icon @click="deleteWo(index)" color="red">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Used</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalUseAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Remain Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalRemainAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-kasbon
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
      type="other"
    ></dialog-kasbon>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogKasbon from "@/components/DialogKasbon";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-settlement-no-kasbon",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-kasbon": DialogKasbon,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      formNumberGk: "",
      date: moment().format("yyyy-MM-DD"),
      employeeId: null,
      name: "",
      division: "",
      paymentMethod: "",
      remark: "",
      currency: "IDR",
      accountNumberId: null,
      totalAmount: 0,
      kasbonNumber: null,
      totalUseAmount: 0,
      totalRemainAmount: 0,
      items: [],
      wos: [],
      bankAccountNumber: "",
      oldAssign: "",
    },
    menuDate: false,
    form: {},
    items: [],
    dialog: false,
    item: {},
    index: -1,
    category: null,
    employee: null,
    listCategory: [],
    valid: true,
    listPaymentMethod: ["Cash", "Transfer"],
    wo: {},
    listWo: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Requirement",
        value: "category",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Start Date",
        value: "startDate",
        sortable: false,
        divider: true,
      },
      {
        text: "End Date",
        value: "endDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Used Amount",
        value: "usedAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Remain Amount",
        value: "remainAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
    headersWo: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "WO Number",
        value: "woId",
        sortable: false,
        divider: true,
      },
      {
        text: "Site",
        value: "sites",
        sortable: false,
        divider: true,
      },
      {
        text: "Date",
        value: "dates",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
    alert: false,
  }),

  computed: {
    totalAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.amount;
      });
      return total;
    },
    totalUseAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.usedAmount;
      });
      return total;
    },
    totalRemainAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.remainAmount;
      });
      return total;
    },

    ...mapState("generalKasbon", ["lastFormNumber", "lastKasbonNumber", "listAccountNumber"]),
    ...mapState("settlementKasbon", ["lastFormNumber"]),
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("external", ["employees"]),
  },

  watch: {},

  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.formNumberGk = this.lastFormNumber;
        this.form.kasbonNumber = this.lastKasbonNumber;
        this.form.totalAmount = this.totalAmount;
        this.form.totalUseAmount = this.totalUseAmount;
        this.form.totalRemainAmount = this.totalRemainAmount;
        this.form.items = this.items;

        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("settlementKasbon/createNoKasbon", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
        this.clear();
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Requirement is required",
        });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    addItem(item) {
      if (this.index > -1) {
        this.items[this.index] = item;
      } else {
        this.items.push({ ...item });
      }
      this.category = null;
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.findIndex(x => x === item);
      this.dialog = true;
    },
    onChangeCategory(val) {
      this.item = val;
      this.index = this.items.findIndex(x => x === val);
      this.dialog = true;
    },
    async getListCategory() {
      const response = await this.$store.dispatch("generalKasbon/getListCategory");
      if (response.status === 200) {
        this.listCategory = response.data;
      }
    },
    async onChangeEmployee(val) {
      this.form.division = val.department.name;
      this.form.employeeId = val.hrId;
      this.form.name = val.name;
      this.form.bankAccountNumber = val.bankAccountNumber;
      this.getWos(val.hrId);
      // await this.$store.dispatch("generalKasbon/getListAccountNumber", val.id);
    },
    async getWos(employeeId) {
      await this.$store
        .dispatch("wo/getByEmployeeId", employeeId)
        .then(response => (this.listWo = response.data));
    },
    onChangeWo(val) {
      if (this.form.wos.findIndex(x => x.woId === val.id) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "WO sudah digunakan",
        });
      } else {
        this.form.wos.push({
          woId: val.id,
          sites: this.woSite(val.tasks),
          dates: this.woDate(val.tasks),
        });
      }
    },
    woSite(tasks) {
      let siteName = [];
      if (tasks && tasks.length > 0) {
        tasks.map(x => {
          if (x.trips && x.trips.length > 0) {
            return x.trips.forEach(y => {
              siteName.push(y.siteName);
            });
          }
        });
        return siteName.filter((v, i, s) => s.indexOf(v) === i).join(",");
      }
      return "";
    },
    woDate(tasks) {
      if (tasks && tasks.length > 0) {
        tasks.map(x => x.date).join(",");
        return tasks
          .map(x => x.date)
          .filter((v, i, s) => s.indexOf(v) === i)
          .join(",");
      }
      return "";
    },
    // eslint-disable-next-line no-unused-vars
    filterObjectWo(item, queryText, itemText) {
      return (
        item.id.toString().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        this.woSite(item.tasks)
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        this.woDate(item.tasks)
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    deleteWo(index) {
      this.form.wos.splice(index, 1);
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("generalKasbon/getLastKasbonNumber"),
        await this.$store.dispatch("currency/getListCurrency"),
        await this.$store.dispatch("external/getEmployees"),
        await this.$store.dispatch("generalKasbon/getLastFormNumber"),
        await this.$store.dispatch("settlementKasbon/getLastFormNumber"),
        this.getListCategory(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
